import React from 'react'
import { Link } from 'gatsby'

import Layout from "../components/layout.js"
import SEO from "../components/seo"

export default () => (
    <Layout page="pltw">
        <SEO title='PLTW Capstone' description="PLTW Final Project"/>
        <h1>PLTW</h1>
        <h2>What is Project Lead the Way (PLTW)?</h2>
        <p>
            PLTW is a national nonprofit that develops STEM curricula for K-12 students.
            At my high school, I am enrolled in PLTW's four year engineering pathway. 
            I started taking this class because I had a growing interest in STEM, 
            particularly in software engineering.
        </p>
        <p>
            This year I am taking Engineering Design & Development, the capstone course. 
            Normally, students would work together in class to work in groups building
            a solution to a design problem; but things are different this year!
        </p>
        {/* <p>
            As class liaison, my goal this year is focused on outreach-- I will work with my
            classmates to connect us to people and resources that will help us overcome 
            the difficulties of engineering over a distance. If you are interested in
            helping out our class, 
            I'd love to <Link to="/contact">hear from you!</Link>
        </p> */}
        <h3>Problem Statement</h3>
        <div style={{display: "flex", flexWrap: "wrap-reverse", justifyContent: "center"}}>
            <p style={{maxWidth: "500px", flexGrow: 2}}>
                “Not in my backyard.”<br/>
                <br/>
                This is the gut reaction that NIMBYs have when they realize that 
                some form of homeless shelter will be built in their neighborhood. 
                Though homelessness is a well-known and targeted issue in Los 
                Angeles, there still are approximately 66,436 homeless people. 
                In light of COVID, this problem is only compounded. However, 
                the biggest impediment to building new housing and homeless 
                shelters isn’t legislation or funding-- it's opposition from people 
                who might be affected by the proposed housing solution. The best 
                difference that can be made to solve this issue comes from garnering 
                the support of housed communities and individuals.
            </p>
            <img style={{maxWidth: "300px", flexGrow: 1}} src={require('../images/pltw-capstone-group-logo.svg')} alt="Logo for my engineering capstone group" />
        </div>
        <h2 id="about">Final Product</h2>
        <p>
            In this website we strive to work together to solve the homeless crisis 
            by learning what actions we can take to be more informed and more respectful 
            of individuals without homes. While learning, we hope to lessen the stigma 
            people hold and reverse some of the prominent stereotypes that impede progress.
        </p>
        <h4>✨<a target="_blank" href="https://la-nimbyism.netlify.app/" target="_blank">Link to website</a></h4>
        <h3>Initial Elevator Pitch</h3>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/hiqgr5_5drw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h3>Additional Links</h3>
        <p><a target="_blank" href="https://www.dropbox.com/s/8w9tpmstfghiot5/Capstone%20Project%20Poster_Homies.pdf?dl=0">Official Project Poster</a></p>
        <p><a target="_blank" href="https://www.dropbox.com/s/8gz5ctqs13syjj1/Capstone%20Final%20Presentation_Homies.pdf?dl=0">Link to Slides (modified)</a></p>
        <h4>Website Outline</h4>
            <ul>
                <li>
                    Landing page
                    <ul>
                        <li>What the website is</li>
                        <li>Statistics about homelessness</li>
                        <li>An informative site that examines both sides of the issue</li>
                        <li>Call to action to donate</li>
                    </ul>
                </li>
                <li>
                    About Us
                    <li>
                        Mission Statement
                    </li>
                </li>
                <li>
                    Education
                    <ul>
                        <li>
                            Individuals can find information about homelessness 
                            such as statistics, guides on how to behave, personal 
                            accounts from homeless individuals, and other resources.
                        </li>
                    </ul>
                </li>
                <li>
                    Donate
                    <ul>
                        <li>individuals can find places to donate money, clothes, food, find volunteer opportunities, etc.</li>
                    </ul>
                </li>
            </ul>
        <h4>Prototype</h4>
        <p>
            You can interact with our UI prototype  
            <a target="_blank" href="https://www.figma.com/proto/vvtKOlbUYOFMqM4nipb3Qo/PLTW-NIMBY-Website-Wireframe?node-id=0%3A1&scaling=scale-down"> here</a>.
        </p>
        <h4>Cost Analysis</h4>
        <img style={{maxWidth: "400px"}} src={require('../images/pltw-capstone-cost-analysis.png')} alt='Website cost analysis'/>
        <h2>Updates</h2>
        <h3>02/03/2021 - Choosing a technology stack!</h3>
        <p>
            While this list is tentative, we will most likely use the following
            to build our site:
        </p>
        <ul>
            <li><a href="https://www.netlify.com/" target="_blank">Netlify</a> to host our website,</li>
            <li><a href="https://www.sanity.io/" target="_blank">Sanity.io</a> for our content management system, and</li>
            <li><a href="https://www.gatsbyjs.com/" target="_blank">Gatsby.js</a>, a static site generator, to code our website.</li>
        </ul>
        <p>We will also host our code on GitHub. Once that's ready, I will add an update here.</p>
        <h3>03/04/2021 - Development</h3>
        <p>
            We are now in the building stages of our first prototype. While the group is writing and curating content
            for the site, I am developing the website locally on my laptop. Once I have implemented our Figma layout, I 
            will link to our GitHub repository. 
        </p>
        <h3>05/25/2021 - PLTW Capstone Completed!</h3>
        <p>
            We've completed the PLTW capstone course, 
            and everything is linked at <a href="#about">this section</a>.
        </p>
    </Layout>
)